@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Chakra%20Petch);
html,
body,
#root {
    height: 100%;
    background: #010812;
    font-family: 'Chakra Petch', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    background: #010812;
    font-family: 'Chakra Petch', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

